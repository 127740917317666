import { Alert, Button, Container, Navbar, Toast, ToastBody, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { missionSelector, showShareModal } from '../mission/store';
import { playerSelector } from '../player/store';
import { RegisterPlayer } from '../player/RegisterPlayer';
import { JoinMission } from '../mission/JoinMission';
import { ShareMission } from '../mission/ShareMission';
import { TaskList } from '../task/TaskList';

import './App.css';

function App() {
	const dispatch = useDispatch();
	const mission = useSelector(missionSelector);
	const player = useSelector(playerSelector);
	const welcome = player.player.PlayerName && `🕵🏻‍♂️ Welcome, Agent ${player.player?.PlayerName}` || 'Missions.';
	const missionCode = mission.mission && `Mission Code: ${mission.mission?.MissionId}` || '';
	const playAgain = () => {
		localStorage.clear();
		window.location.reload();
	};


	return (
		<div className="App">
			<Navbar bg="light" expand="lg">
				<Container>
					<Navbar.Brand href="#home">
						<h2>{welcome}</h2></Navbar.Brand>
					<Navbar.Text>
						{mission.mission && <h2><Button onClick={() => dispatch(showShareModal())}>{missionCode}</Button></h2>}
					</Navbar.Text>
				</Container>
			</Navbar>
			<JoinMission />
			<RegisterPlayer />
			<TaskList />
			<ShareMission />
			
			<Alert variant='danger' show={mission.status === 'error'}>
				Sorry, we can&#39;t find a mission with that ID!
			</Alert>

			<ToastContainer position='bottom-center'>
				<Toast show={player.player.Tasks != undefined && player.player.Tasks?.every(t => t.isCompleted)}>
					<ToastBody>
						<h3>YOU WIN!!! 🚀 <br /></h3>
						<Button onClick={() => playAgain()}>Play Again?</Button>
					</ToastBody>
				</Toast>
			</ToastContainer>
		</div>
	);
}

export default App;
