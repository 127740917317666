import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';

import { createMissionAsync, joinMissionAsync, missionSelector } from './store';

export function JoinMission() {
	const dispatch = useDispatch();
	const state = useSelector(missionSelector);
	const [missionId, setMissionId] = useState('');

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setMissionId(e.target.value);
	};

	return (
		<Modal show={state.showMissionModal && state.status !== 'error'}>
			<Modal.Header>
				<Modal.Title>Mission Start</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="d-grid gap-2">
					<InputGroup className="mb-3">
						<FormControl onChange={handleChange} placeholder="Mission Code?" maxLength={4} style={{ textTransform: 'uppercase' }} />
						<Button disabled={missionId.length < 4} onClick={() => dispatch(joinMissionAsync(missionId))} variant="outline-primary" id="button-addon2">Join</Button>
					</InputGroup>

					<hr />

					<Button onClick={() => dispatch(createMissionAsync())}>Create Mission!</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
