import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { playerSelector } from '../player/store';
import { Task } from './Task';

export function TaskList() {
	const playerState = useSelector(playerSelector);
 
	if(!playerState) return (<></>);

	return (		
		<Container>
			<Row className="justify-content-md-center">
				<h1>Your Tasks</h1>
				<hr />
			</Row>
			
			<Row className="justify-content-md-center">
				<Col />
				<Col xs={6}>
					{playerState.player?.Tasks?.map((t, i) => (
						<React.Fragment key={i}>
							<Task isCompleted={t.isCompleted} description={t.description} id={t.id} key={t.id} />
							<br />
						</React.Fragment>
					))}
				</Col>
				<Col />
			</Row>
		</Container>
	);
}
