import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../app/types';

import { createMission, joinMission } from './api';
import { Mission, MissionState } from './types';

const initialState: MissionState = { mission: undefined, status: 'idle', showMissionModal: true, showShareModal: false };
const pendingReducer = (state: MissionState) => { state.status = 'loading'; };
const rejectedReducer = (state: MissionState) => { state.status = 'error'; };
const fulfilledReducer = (state: MissionState, action: { payload: Mission }) => { 
	state.mission = action.payload;
	state.showMissionModal = false;
	state.status = 'idle';
};

export const missionSlice = createSlice({
	name: 'mission',
	initialState,
	reducers: {
		showShareModal(state: MissionState) { state.showShareModal = true; },	
		hideShareModal(state: MissionState) { state.showShareModal = false; },
		resetMissionStatus(state: MissionState) { state.status = 'idle'; },
	},
	extraReducers: (builder) => {
		builder
			.addCase(createMissionAsync.pending, pendingReducer)
			.addCase(createMissionAsync.fulfilled, fulfilledReducer)
			.addCase(joinMissionAsync.pending, pendingReducer)
			.addCase(joinMissionAsync.fulfilled, fulfilledReducer)
			.addCase(joinMissionAsync.rejected, rejectedReducer);
	}
});

export const missionSelector = (state: AppState) => state.mission;
export const missionReducer = missionSlice.reducer;
export const createMissionAsync = createAsyncThunk('mission/create', async () => await createMission());
export const joinMissionAsync = createAsyncThunk('mission/join', async (missionCode: string) => await joinMission(missionCode));
export const { showShareModal, hideShareModal, resetMissionStatus } = missionSlice.actions;
