import QRCode from 'react-qr-code';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { hideShareModal, missionSelector } from './store';

export function ShareMission() {
	const dispatch = useDispatch();
	const state = useSelector(missionSelector);
	
	return (
		<Modal show={state.showShareModal}>
			<Modal.Header>
				<Modal.Title>Burn after reading...</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row className="justify-content-md-center">
					<Col />
					<Col xs={6}>
						<QRCode value={`https://missions.thesheps.dev/${state.mission?.MissionId}`} />
					</Col>
					<Col />
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="primary" onClick={() => dispatch(hideShareModal())}>Cool!</Button>
			</Modal.Footer>
		</Modal>
	);
}