import axios from 'axios';

import config from '../config';
import { Player } from './types';

export async function registerPlayer(player: Player) {
	const response = await axios.post(config.playerApiUrl, player);
	return response.data;
}

export async function updateTask(player: Player, taskId: string) : Promise<Player> {
	const tasks = (player.Tasks || []).map(t => {
		if(t.id === taskId) return { ...t, isCompleted: true };
		return t;
	});

	const response = await axios.put(config.playerApiUrl, {
		...player,
		Tasks: tasks
	});
	
	return response.data;
}
