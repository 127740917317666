import axios from 'axios';

import config from '../config';

export async function createMission() {
	const mission = await axios.post(config.missionApiUrl);
	return mission.data;
}

export async function joinMission(missionCode: string) {
	const mission = await axios.get(`${config.missionApiUrl}/${missionCode}`);

	if(mission.data.length === 0) throw new Error('Cannot find mission!');
	return mission.data;
}