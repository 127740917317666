import { Badge, Button, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { playerSelector, updateTaskAsync } from '../player/store';

export interface TaskProps {
    id: string;
    description: string;
    isCompleted: boolean;
}

export function Task(props: TaskProps) {
	const dispatch = useDispatch();
	const player = useSelector(playerSelector);
	const playerUpdate = { player: player.player || '', taskId: props.id };

	return (		
		<Card>
			<Card.Header>
				{props.isCompleted && <Badge style={{marginRight: 'auto'}}>Completed!</Badge> || props.id}
			</Card.Header>

			<Card.Body>
				<Card.Text>
					{props.description}
				</Card.Text>
			</Card.Body>

			<Card.Footer className="d-grid gap-2">
				<Button
					disabled={props.isCompleted}
					variant="success"
					onClick={() => dispatch(updateTaskAsync(playerUpdate))}>Complete!</Button>
			</Card.Footer>
		</Card>
	);
}
