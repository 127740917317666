import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { registerPlayer, updateTask } from './api';
import { createMissionAsync, joinMissionAsync } from '../mission/store';
import { Player, PlayerState } from './types';
import { AppState } from '../app/types';

const initialState: PlayerState = { player: {}, status: 'idle', showRegisterPlayerModal: false };
const missionJoinedReducer = (state: PlayerState) => { state.showRegisterPlayerModal = true; };
const pendingReducer = (state: PlayerState) => { state.status = 'loading'; };
const taskUpdatedReducer = (state: PlayerState, action: { payload: Player }) => { state.player = action.payload; };

const playerRegisteredReducer = (state: PlayerState, action: { payload: Player }) => { 
	state.showRegisterPlayerModal = false;
	state.status = 'idle';
	state.player = action.payload;
};

export const playerSlice = createSlice({
	name: 'mission',
	initialState,
	reducers: { },
	extraReducers: (builder) => {
		builder
			.addCase(createMissionAsync.fulfilled, missionJoinedReducer)
			.addCase(joinMissionAsync.fulfilled, missionJoinedReducer)
			.addCase(registerPlayerAsync.pending, pendingReducer)
			.addCase(registerPlayerAsync.fulfilled, playerRegisteredReducer)
			.addCase(updateTaskAsync.pending, pendingReducer)
			.addCase(updateTaskAsync.fulfilled, taskUpdatedReducer);
	}
});

export const playerSelector = (state: AppState) => state.player;
export const playerReducer = playerSlice.reducer;
export const registerPlayerAsync = createAsyncThunk('player/register', async (payload: Player) => await registerPlayer(payload));
export const updateTaskAsync = createAsyncThunk('player/updateTask', async (payload: { player: Player, taskId: string }) => await updateTask(payload.player, payload.taskId));
