import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';

import { playerSelector, registerPlayerAsync } from './store';
import { missionSelector } from '../mission/store';

export function RegisterPlayer() {
	const dispatch = useDispatch();
	const playerState = useSelector(playerSelector);
	const missionState = useSelector(missionSelector);
	const [name, setName] = useState('');

	const player = {
		MissionId: missionState.mission?.MissionId || '',
		PlayerName: name
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setName(e.target.value);
	};

	return (
		<Modal show={playerState.showRegisterPlayerModal}>
			<Modal.Header>
				<Modal.Title>May I ask your name?</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="d-grid gap-2">
					<InputGroup className="mb-3">
						<FormControl onChange={handleChange} placeholder="Name" />
						<Button disabled={name.length === 0} onClick={() => dispatch(registerPlayerAsync(player))} variant="outline-primary" id="button-addon2">Join</Button>
					</InputGroup>
				</div>
			</Modal.Body>
		</Modal>
	);
}
