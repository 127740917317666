import { configureStore } from '@reduxjs/toolkit';

import { joinMissionAsync, missionReducer, resetMissionStatus } from '../mission/store';
import { playerReducer } from '../player/store';

const state = localStorage.getItem('reduxState');

const preloadedState = state
	? JSON.parse(state || '')
	: {};

export const store = configureStore({
	reducer: { mission: missionReducer, player: playerReducer },
	preloadedState,
});

store.subscribe(() => {
	localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

if(!preloadedState || !preloadedState.mission || !preloadedState.mission.mission) {
	const missionCode = window.location.pathname.replace('/', '');

	if(missionCode) {
		store.dispatch(joinMissionAsync(missionCode));
		setTimeout(() => {store.dispatch(resetMissionStatus());}, 5000);
	}
}
