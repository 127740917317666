import Amplify from 'aws-amplify';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app/App';
import awsExports from './aws-exports';
import { store } from './app/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter } from 'react-router-dom';

Amplify.configure(awsExports);

ReactDOM.render(
	<StrictMode>
		<Provider store={store}>
			<HashRouter>
				<App />
			</HashRouter>
		</Provider>
	</StrictMode>,
  
	document.getElementById('root')
);

